@include media-breakpoint-up(sm){
	.detail-ticket {
		height: 100%;
		overflow: hidden;
		position: relative;
		>.ant-row {
			position: absolute; top: 0; left: 0;
			width: 100%;
			height: 100%;
			>.ant-col {
				height: 100%;
				overflow-y: hidden;
			}
		}
	}
}

.left-detail-ticket {
	height: 100%;
	overflow-y: scroll;
}

.right-detail-ticket {
	padding: 10px;
	@include media-breakpoint-up(sm){
		padding: 10px 10px 10px  0;
	}
}

.middle-detail-ticket {
	background: #ebf7ff;
	margin: 10px;
	border-radius: 3px;
	padding: 20px;
	@include media-breakpoint-up(sm){
		margin: 10px 10px 10px 0;
		height: calc(100% - 20px);
		overflow-y: scroll;
	}
}
.middle-item {
	&+.middle-item {
		margin-top: 20px;
	}
	.p-value {
		background: rgba(95, 148, 224, .2);
	}
}
.p-label {
	margin-bottom: 10px;
}
.p-value {
	line-height: 32px;
	border-radius: 3px;
	padding: 0 10px;
	font-weight: 500;
}
h1.p-value {
	padding: 0;
}
.p-status {
	color: #ffffff;
}
.p-type {
	line-height: 32px;
	border-radius: 3px;
	padding: 0 10px;
	font-weight: 500;
	background: #DA6701;
	color: #ffffff;
}
.p-content {
	ul {
		display: block;
		list-style: disc;
		margin-block-start: .5em;
		margin-block-end: .5em;
		margin-inline-start: 0px;
		margin-inline-end: 0px;
		padding-inline-start: 30px;

	}
	ol {
		display: block;
		list-style-type: decimal;
		margin-block-start: .5em;
		margin-block-end: .5em;
		margin-inline-start: 0px;
		margin-inline-end: 0px;
		padding-inline-start: 30px;
	}
}
.ck-editor__editable {
	height: 150px;
}
/*-----------log-ticket------------*/

.log-ticket {
	border: 1px solid #d9e6f7;
	padding: 5px 10px;
	background: #f5faff;
	font-size: 13px;
	border-radius: 3px;
	height: 500px;
	@include media-breakpoint-up(sm){
		height: calc(50% - 3px);
	}
	@include media-breakpoint-down(md){
		max-height: 500px;
	}
	>p {
		font-size: 14px;
		font-weight: 600;
		color: #0148ad;
		text-transform: uppercase;
		margin-bottom: 5px;
		line-height: 24px;
	}
	.add-comment {
		width: 100%;
	}
	.wrapper-logs-ticket {
		overflow-y: scroll;
	}
	.upload-image-comment {
		position: absolute;
		right: 3px;
		top: 6px;
		cursor: pointer;
	}
	.icon_loading {
		position: absolute;
		top: 50px;
		right: 50px;
		vertical-align: middle;
	}
}

.actions-ticket {
	margin-top: 6px;
	.wrapper-logs-ticket {
		height: calc(100% - 30px);
	}
}

.comments-ticket {
	.wrapper-logs-ticket {
		height: calc(100% - 30px);
	}
}

.log-item {
	display: flex;
	justify-content: space-between;
	&+.log-item {
		border-top: 1px solid #eeeeee;
		margin-top: 10px;
		padding-top: 10px;
	}
	>span {
		width: 35px;
		height: 35px;
		border-radius: 50px;
		overflow: hidden;
		align-items: center;
		justify-content: center;
		display: flex;
		background: #dddddd;
		border: 1px solid #cccccc;
		img {
			max-width: 100%;
			max-height: 100%;
			object-fit: cover;
		}
	}
	>div {
		width: calc(100% - 45px);
		color: #555555;
		div {
			line-height: 18px;
			word-break: break-word;
			strong {
				font-weight: 500;
				color: #444444;
			}
		}
		p {
			font-size: 12px;
			display: flex;
			width: 100%;
			justify-content: space-between;
			i {
				color: #888888;
			}
		}
		a {
			color: #888888;
		}
	}
	&:hover {
		>div a {
			color: $txt-color--blue0;
		}
	}
	img.image-comment {
		height: 50px;
		max-width: 100%;
	}
}

/*-----------left-detail-ticket------------*/

.ticket-detail {
	.ant-row .ant-col {
		min-height: 32px;
		//display: flex;
		//align-items: center;
		&:last-child {
			font-weight: 500;
		}
	}

	.file-item {
		&:hover > span {
			color: $txt-color--redf;
			cursor: pointer;
		}
	}
}

h3.sperator {
	background: #e2ecf9;
	color: #0048ad;
}

.follow_btn {
	//border: 1px solid #00a7de;
	//background: #00c1ff;
	color: #ffffff;
	@include media-breakpoint-down(ms){
		width: 100%;
	}
}

.followed-members {
	.add-member {
		font-size: 14px;
		color: rgba(0, 0, 0, 0.85);
		cursor: pointer;
	}
	ul {
		display: flex;
		li {
			&+li {
				margin-left: 10px;
			}
			img {
				height: 45px;
				width: 45px;
				object-fit: cover;
				border-radius: 100%;
				-webkit-border-radius: 100%;
				border: 2px solid #ffffff;
			}
		}
		.member {
			color: #0048ad;
			text-transform: uppercase;
			cursor: pointer;
		}
	}
}

.ticket-detail-creator {
	color: #888888;
	font-size: 13px;
	strong {
		font-weight: 500;
		color: #000000;
	}
}

.add-follower {
	width: 100%;
	margin-top: 10px;
	max-height: 500px;
	overflow: auto;

	.member {
		display: flex;
		align-items: center;
		padding: 8px 10px;
		color: #0048ad;
		cursor: pointer;

		&:hover {
			background: rgb(237, 247, 254)
		}
		img {
			height: 45px;
			width: 45px;
			object-fit: cover;
			border-radius: 100%;
			-webkit-border-radius: 100%;
			border: 2px solid #ffffff;
			margin-right: 10px;
		}
	}
}

.create_us_taiga {
	//border: 1px solid #00a7de;
	//background: #00c1ff;
	color: #ffffff;

}

