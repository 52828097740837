$color-text-menu: #cee2ff;
$color-text-breadcrumb: #749edc;

#root {
	min-width: 100%;
	min-height: 100%;
	display: flex;
	align-items: stretch;
}
.site-logo {
	width: 120px;
	height: 31px;
	background: rgba(255, 255, 255, 0.2);
	margin: 16px 28px 16px 0;
	float: left;
	cursor: pointer;
}
.site-content {
	padding: 2rem 2rem 1.5rem;
	@include media-breakpoint-down(sm) {
		padding: 10px;
		header.ant-layout-header {
			height: auto;
			padding: 10px !important;
			line-height: 24px;
			h2 {
				font-size: 16px;
			}
		}
	}
}

.main-menu {
	background: $color--primary;
	color: #ffffff;
	@include media-breakpoint-down(sm) {
		position: fixed; top: 0; left: -80px;
		height: 100%;
		z-index: 8;
		&.expanded-mobile {
			left: 0px;
			box-shadow: 0 0 10px 0 rgba(0,0,0,.3);
		}
	}
}

.toggle-menu {
	position: relative;
	margin: 0 26px;
	height: 17px;
	width: 26px;
	display: flex;
	align-items: center;
	cursor: pointer;
	span {
		display: block;
		width: 26px;
		height: 1px;
		background: #ffffff;
		position: absolute;
		&:before, &:after  {
			height: 1px;
			width: 100%;
			background: #fff;
			content: "";
			position: absolute;
			left: 0;
			transition: all .3s;
		}
		&:before {
			top: -8px;
		}
		&:after {
			bottom: -8px;
		}
	}
	&.expanded {
		span {
			background: transparent;
			&:before {
				top: 0;
				transform: rotate(45deg);
			}
			&:after {
				bottom: 0;
				transform: rotate(-45deg);
			}
		}
	}
}

.left-menu {
	.ant-menu {
		background: transparent;
		li.ant-menu-item, li.ant-menu-submenu > div {
			display: flex;
			align-items: center;
		}
	}
	>.ant-menu {
		padding-top: 15px;
	}
}

.ant-menu-submenu-active,
.ant-menu-submenu-title:hover {
	color: #ffffff !important;
}

.toggle-submenu {
	position: absolute;
	bottom: 21px;
	left: 100%;
	z-index: 9;
	cursor: pointer;
	margin: 0;
	height: 40px;
	width: 40px;
	border-radius: 0 5px 5px 0;
	display: flex;
	align-items: center;
	background-color: $color--primary;
	span {
		display: block;
		width: 26px;
		height: 1px;
		background: #ffffff;
		position: absolute;
		left: 6px;
		&:before, &:after  {
			height: 1px;
			width: 100%;
			background: #fff;
			content: "";
			position: absolute;
			left: 0;
			transition: all .3s;
		}
		&:before {
			top: -8px;
		}
		&:after {
			bottom: -8px;
		}
	}
}
.expanded-mobile {
	span {
		background: transparent;
		&:before {
			top: 0;
			transform: rotate(45deg);
		}
		&:after {
			bottom: 0;
			transform: rotate(-45deg);
		}
	}
}

.left-menu .ant-menu-dark ul.ant-menu-inline.ant-menu-sub {
	background: transparent !important;
}
.left-menu .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
	background-color: rgba(255,255,255,.2);
}
.ant-menu.ant-menu-dark, .ant-menu-dark .ant-menu-sub, .ant-menu.ant-menu-dark .ant-menu-sub {
	background-color: $color--primary !important;
}
.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
	background-color: rgba(255,255,255,.1) !important;
}

/*---------------header----------------*/

.page-header {
	height: 60px;
	width: 100%;
	box-shadow: 0 0 5px 5px rgba(0,0,0,.15);
	background: $color--primary;
	padding: 0;
	display: flex;
	align-items: center;
	justify-content: space-between;
	z-index: 9;
	position: fixed;
	@include media-breakpoint-down(sm){
		padding: 0;
	}
	&.ant-layout-header {
		line-height: 32px;
	}
	h2 {
		color: #ffffff;
	}
}

#dashboard-body {
	margin-top: 60px;
}

#page-body {
	position: fixed;
	width: 100%;
	height: calc(100% - 60px);
	bottom: 0;
	left: 0;
}

.breadcrumb-header {
	color: $color-text-breadcrumb;
	border-left: 1px solid rgba(160, 190, 255, .1);
	margin-left: 20px;
	padding-left: 20px;
	position: relative;
	&:before {
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		content: "";
		border-left: 1px solid rgba(0, 0, 0, .15);
	}
	span {
		color: $color-text-breadcrumb;
	}
	a {
		color: $color-text-breadcrumb;
		&:hover {
			color: #ffffff;
		}
	}
}

.navbar {
	display: flex;
	justify-content: space-between;
	.main-navbar {
		display: flex;
		a {
			font-size: 24px;
			color: #ffffff;
			display: flex;
			justify-content: center;
			align-items: center;
			height: 60px;
			width: 70px;
			background: rgba(255,255,255,.1);
			margin-right: 1px;
			&:hover {
				background: rgba(255,255,255,.2);
			}
		}
	}
	.member-navbar {
		height: 60px;
		display: flex;
		align-items: center;
		position: relative;
		> a {
			display: flex;
			align-items: center;
			padding: 0 10px;
			height: 60px;
			img {
				height: 45px;
				width: 45px;
				object-fit: cover;
				border-radius: 100%;
				-webkit-border-radius: 100%;
				-moz-border-radius: 100%;
				border: 2px solid #ffffff;
			}
			&:hover {
				background: rgba(255,255,255,.3);
				img {
					border-color: #ffffff;
				}
			}
		}
	}
	@include media-breakpoint-down(sm){
		h2 {
			flex-grow: 2;
			font-weight: normal;
			a {
				color: #ffffff;
				display: block;
				line-height: 60px;
				text-align: center;
			}
		}
		.main-navbar {
			flex-grow: 8;
		}
	}
}

.sub-navbar {
	.ant-dropdown-arrow {
		border-top-color: $color--primary;
		border-left-color: $color--primary;
	}
	ul {
		width: 240px;
		background: $color--primary;
		padding: 10px 15px;
		box-shadow: -2px 2px 0 0 rgba(0,0,0,.2);
		li {
			&+li {
				border-top: 1px solid rgba(255,255,255,.1);
			}
			&:hover {
				background: transparent;
			}
			a {
				color: $color-text-menu;
				line-height: 32px;
				display: block;
				&:hover {
					color: #ffffff;
					background: transparent;
				}
			}
		}
	}
}

.projects-navbar {
	ul {
		width: 330px;
		li {
			white-space: normal !important;
			&+li {
				border-top: 1px solid #eeeeee;
			}
			a {
				font-size: 16px !important;
				padding: 15px;
				line-height: 22px;
				word-break: break-word;
				&:hover {
					color: $color--primary;
				}
			}
		}
	}
	&.scroller ul {
		height: 320px;
		overflow-y: scroll;
	}
}

.title-page {
	>h2 {
		text-transform: uppercase;
	}
}

.ant-form-item-explain.ant-form-item-explain-error {
	font-size: 12px !important;
}
