.item-project {
  border-bottom: 1px solid #e4e3e3 !important;
  position: relative;
  .pointer-sort {
    cursor: all-scroll;
  }
  &:active {
    cursor: grabbing;
    cursor: -moz-grabbing;
    cursor: -webkit-grabbing;
  }
  &:hover {
    background-color: #7d6c6c12;
  }
  >p {
    position: absolute;
    top: 1rem;
    left: 1rem;
  }
  >.ant-row {
    margin-left: 80px;
    min-height: 64px;
  }
}
.header-list-project {
  background-color: #7d6c6c12;
}

.connect-dingtalk >  span {
  font-size: 30px;
}

.brief-project {
  a {
    color: #000000;
    font-weight: 500;
  }
  p {
    color: #888888;
  }
}

body>li.item-project {
  padding: 0;
  margin: 0;
  list-style: none;
  box-shadow: 2px 2px 5px rgba(0,0,0,.1);
  background: #f0f0f0;
  .ant-col {
    border-bottom: 1px solid #f0f0f0;
  }
}

