.nav-settings {
	a {
		display: inline-block;
		background: #ffffff;
		padding: 10px 20px;
		margin-right: 10px;
		color: #444444;
		box-shadow: 1px 1px 0 rgba(0,0,0,.1);
		opacity: .8;
		transition: all .3s;
		font-weight: 500;
		&:hover, &.active {
			color: $color--primary;
			box-shadow: 1px 1px rgba(0,0,0,.2);
			opacity: 1;
		}
		&.active {
			color: #ffffff;
			background: $color--primary;
			box-shadow: 1px 1px #e3e8ef;
		}
		@include media-breakpoint-down(md) {
			width: calc(50% - 6px);
			margin-right: 0;
			padding: 10px 0;
			text-align: center;
			margin-bottom: 5px;
			&:nth-child(2n+2) {
				margin-left: 6px;
			}
		}
	}
}

.category-page {
	background: #ffffff;
	.ant-col {
		padding: 16px;
		border-bottom: 1px solid #f0f0f0;
		@include media-breakpoint-down(md) {
			padding: 16px 10px;
		}
	}
	ul .ant-row {
		&:hover {
			background-color: #ebf5ff;
		}
	}
}

.category-header {
	background: #f4f7fd;
	font-size: 14px;
	font-weight: 500;
	color: $color--primary;
}
body>li.setting-item {
	padding: 0;
	margin: 0;
	list-style: none;
	box-shadow: 2px 2px 5px rgba(0,0,0,.1);
	background: #f0f0f0;
	.ant-col {
		padding: 16px;
		border-bottom: 1px solid #f0f0f0;
	}
}
.pointer-sort {
	display: inline-block;
	width: 22px;
	height: 16px;
	position: relative;
	box-shadow: 0 2px #999999;
	margin-top: 2px;
	cursor: move;
	&:before, &:after  {
		height: 2px;
		width: 100%;
		background: #999999;
		content: "";
		position: absolute;
		left: 0;
		transition: all .3s;
	}
	&:before {
		top: 0;
	}
	&:after {
		bottom: 6px;
	}
}

.department-page {
  background: #ffffff;
  .ant-col {
    padding: 16px;
    @include media-breakpoint-down(md) {
      padding: 16px 10px;
    }
  }
}
