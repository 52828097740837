.project-settings {
	max-width: 900px;
	margin: 0 auto;
	h1 {
		text-transform: uppercase;
	}
}

.project-settings-creator-info {
	font-style: italic;
	color: #888888;
	span {
		font-weight: 600;
		color: $color--primary;
	}
}

.project-settings-label-block {
	margin-top: 5px;
	margin-bottom: 16px;
	text-transform: uppercase;
	font-weight: 600;
	font-size: 1.1em;
	color: $color--primary;
}
