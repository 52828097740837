.upload-logo {
	.ant-upload {
		width: 150px;
		height: 160px;
	}
	img {
		object-fit: cover;
		max-height: 100%;
		max-width: 100%;
	}
}
