.status-ticket {
  background-color: $color--primary;
  color: #ffffff;
  border-radius: 100%;
}

.status-ticket-item {
  background-color: $color--primary;
  color: #ffffff;
  width: 12px;
  height: 12px;
  text-align: center;
  display: block;
  margin: 0 auto;
  border-radius: 100%;
  text-transform: uppercase;
}

/*-----------Table----------*/

.search-form-tickets {
  background-color: #fbfbfb;

  p {
    line-height: 1.5rem;
  }
}

.table-ticket-list {
	background: #ffffff;
	@include media-breakpoint-down(md) {
		display: block !important;
		background: transparent;
	}
}


.filter-btn {
	margin: 10px 0;
	p {
		cursor: pointer;
		text-align: center;
		background: #ffffff;
		font-weight: 500;
		padding: 10px 0;
		box-shadow: 1px 1px 1px 0 rgba(0,0,0,.1);
		&.active {
			background: $color--primary;
			color: #ffffff;
		}
	}
}

.status-filter-tickets {
	@include media-breakpoint-down(md) {
		width: 100% !important;
		max-width: 100% !important;
		display: block;
		margin: 10px 0;
	}
	li {
		&+li {
			border-top: 1px solid #eeeeee;
		}
		&:first-child {
			position: relative;
			.all_closed_status {
				display: block;
				position: absolute; top: 5px; right: 5px;
				line-height: 18px;
				color: #888888;
				&:hover, &.active {
					background: transparent;
					color: $color--primary;
				}
			}
		}
		a {
			padding: 10px;
			display: flex;
			line-height: 28px;
			align-items: center;
			color: #333333;
			transition: all .3s;
			&:hover, &.active {
				background-color: rgba(0, 72, 173, .2);
				color: rgba(0, 72, 173, 1);
			}
			&.disabled {
				background: #eeeeee;
				opacity: .5;
				&:hover {
					color: #222222;
				}
			}
			strong {
				font-weight: normal;
				text-transform: capitalize;
			}
			span {
				width: 16px;
				height: 16px;
			}
		}
	}
}

.ticket-list-table .ant-table-thead > tr > th {
  color: #0148ad !important;
  background-color: #f4f7fd !important;
}

.ticket-list-table .ant-table-tbody .ant-table-row:hover {
  td {
    background-color: #ebf5ff;
  }
}

.ant-table-thead > tr > th, .ant-table-tbody > tr > td, .ant-table tfoot > tr > th, .ant-table tfoot > tr > td {
  padding-right: 10px !important;
  padding-left: 10px !important;
}

.max-title {
  max-width: 250px;
}


/*-----------Kanban----------*/
#root .ant-layout {
	width: 100%;
}
#layout-kanban {
	overflow: scroll;
	display: flex;
}

.kanban-column {
	width: 290px;
	height: calc(100vh - 228px);
	margin-right: 10px;
	margin-bottom: 15px;
}

.kanban-column-wrap {
	background-color: #fafafa;
	max-height: calc(100vh - 228px);
	width: 284px;
}

.kanban-column-header {
	font-size: 14px;
	font-weight: 500;
	border-top-width: 3px;
	border-top-style: solid;
	padding: 10px;
	text-transform: uppercase;
}

.kanban-column-body {
	padding: 0 10px;
	min-height: 38px;
	max-height: calc(100vh - 276px);
	overflow: auto;
}

.ticket-kanban {
	background: #ffffff;
	width: 100%;
	border: 1px solid #dddddd;
	box-shadow: 1px 1px 2px rgba(99,99,99,.15);
	position: relative;
	padding-top: 40px;
	margin-bottom: 10px;
	transition: all .3s;
	&:hover {
		border: 1px solid #aaaaaa;
		box-shadow: 1px 1px 2px rgba(99,99,99,.4);
	}
}

.ticket-kanban-header {
	height: 40px;
	position: absolute;
	top: 0; left: 0;
	width: 100%;
	.ticket-kanban-header-content {
		height: 40px;
		cursor: pointer;
		align-items: center;
		display: flex;
		.assign-name {
			margin-left: 10px;
			width: 200px;
			text-overflow: ellipsis;
			white-space: nowrap;
			overflow: hidden;
			color: gray;
		}

		.ant-avatar-square {
			border-radius: 0;
		}
	}
	.ticket-kanban-header-action {
		width: 100%;
		background-color: rgba(255, 255, 255, 0.7);
		position: absolute;
		padding: 5px;
		z-index: 6;
		display: none;
		align-items: center;
		justify-content: space-between;
		.anticon-user-add {
			margin-right: 3px;
		}
		>div {
			cursor: pointer;
			padding: 5px;
			&:hover {
				color: #0048ad;
			}
		}
		p>span {
			display: inline-block;
			padding: 5px;
			cursor: pointer;
			&:hover {
				color: #0048ad;
			}
		}
	}
	&:hover .ticket-kanban-header-action {
		display: flex;
	}
}

.ticket-kanban-body {
	font-size: 15px;
	line-height: 20px;
	cursor: move;
	word-break: break-word;
	a {
		color: #444444;
		&:hover {
			color: #0048ad;
			cursor: pointer;
		}
	}
	p {
		>span, >strong {
			background: #ff9e08;
			border-radius: 3px;
			text-align: center;
			padding: 5px;
			color: #ffffff;
			font-size: 12px;
		}
		>strong {
			font-weight: normal;
			background: $color--primary;
			color: #ffffff;
		}
	}
}


/*----------Assign Kanban-----------*/

.member-modal {
	border-radius: 50px;
}

.list-member-modal {
	p {
		background: transparent;
		transition: all .3s;
		cursor: pointer;
		&+p {
			border-top: 1px solid #eeeeee;
		}
		&:hover {
			background: #fcfff2;
		}
	}
}

.logo-goticket-rate-star {
  background-size: cover;
  background-position: center;
  display: block;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 150px;
  background-image: url("./../../img/goticket.jpg");
}
