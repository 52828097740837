.list-roles {
	box-shadow: 1px 1px 1px rgba(0,0,0,.1);
	ul {
		li {
			border-bottom: 1px solid #dddddd;
			a {
				font-weight: 500;
				font-size: 15px;
				display: block;
				color: #444444;
				transition: all .3s;
				&:hover {
					background-color: #ebf5ff;
					color: $color--primary;
				}
				&.active {
					background: $color--primary;
					color: #ffffff;
				}
			}
		}
	}
	p {
		position: relative;
		a {
			position: absolute;
			top: 14px; right: 40px;
			font-size: 18px;
			color: $color--primary;
			&:last-child {
				right: 17px;
				color: $txt-color--redf;
			}
		}
	}
}
.list-permission {
	.title-list-permission {
		box-shadow: 1px 1px 1px rgba(0,0,0,.1);
	}
	.title-panel {
		span {
			font-weight: normal;
		}
	}
}
.panel-permission {
	ul {
		li+li {
			border-top: 1px solid #dddddd;
		}
		li {
			display: flex;
			justify-content: space-between;
		}
	}
	.ant-collapse-content-box {
		padding-top: 5px;
		padding-bottom: 5px;
	}
}
p.edit-name {
	position: relative;
	a {
		position: absolute;
		top: 3px; right: 35px;
		font-size: 18px;
		color: $color--primary;
		&:last-child {
			right: 10px;
			color: $txt-color--redf;
		}
	}
}
