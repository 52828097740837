//@import '~antd/dist/antd.css';
@import "custom-antd.css";
@import "bootstrap/bootstrap";
@import "generics/generic.box-sizing";
@import "generics/generic.normalize";
@import "generics/generic.reset";
@import "settings/setting.color";
@import "settings/setting.text";
@import "components/layout";
@import "components/create-project";
@import "components/project";
@import "components/member";
@import "components/detail-ticket";
@import "components/tickets";
@import "components/settings";
@import "components/roles";
@import "components/project-settings";
