.search-add-member {
  span.ant-input-wrapper.ant-input-group {
    display: inline-flex;
  }
}
.disabled-checkbox-remove-member {
    display: none !important;
}
div.action-manage-member {

    padding: 0 !important;
    font-size: 20px;

}